import Head from 'next/head'
import { useRouter } from 'next/router'
import { FC, useCallback, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import BackgroundVideo from 'sections/homepage/BackgroundVideo'
import Blog from 'sections/homepage/Blog'
import Hero from 'sections/homepage/Hero'
import MainPartners from 'sections/homepage/MainPartners'
import Stats from 'sections/homepage/Stats'
import BaseReferralModal from 'sections/referrals/ReferralModal/BaseReferralModal'
import HomeLayout from 'sections/shared/Layout/HomeLayout'
import { setOpenModal } from 'state/app/reducer'
import { selectShowModal } from 'state/app/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { fetchUnmintedBoostNftForCode } from 'state/referrals/action'
import { selectIsReferralCodeValid } from 'state/referrals/selectors'

type AppLayoutProps = {
	children: React.ReactNode
}

type HomePageComponent = FC & { layout?: FC<AppLayoutProps> }

const HomePage: HomePageComponent = () => {
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const router = useRouter()
	const routerReferralCode = (router.query.ref as string)?.toLowerCase()
	const isReferralCodeValid = useAppSelector(selectIsReferralCodeValid)
	const openModal = useAppSelector(selectShowModal)

	useLayoutEffect(() => {
		if (router.isReady && routerReferralCode) {
			dispatch(fetchUnmintedBoostNftForCode(routerReferralCode))
		}
	}, [dispatch, router.isReady, routerReferralCode])

	useLayoutEffect(() => {
		if (isReferralCodeValid) {
			dispatch(setOpenModal('referrals_mint_boost_nft'))
		}
	}, [dispatch, isReferralCodeValid])

	const onDismiss = useCallback(() => {
		dispatch(setOpenModal(null))
	}, [dispatch])

	return (
		<>
			<Head>
				<title>{t('homepage.page-title')}</title>
			</Head>
			<Layout>
				<Hero />
				<Stats />
				<MainPartners />
				<Blog />
				<BackgroundVideo />
			</Layout>
			{openModal === 'referrals_mint_boost_nft' && routerReferralCode && isReferralCodeValid && (
				<BaseReferralModal onDismiss={onDismiss} referralCode={routerReferralCode} />
			)}
		</>
	)
}

const Layout = styled(HomeLayout)`
	& main {
		overflow: hidden;
	}
`

export default HomePage
